<template src="./productList.html"></template>

<script>
import header from '../header/header';

export default {
  name: 'promotion',
  components: {
    recommendationHeader: header,
  },
  data() {
    return {
      quotes: null,
      showModals: false,
      isPromotionLoading: false,
      promotion: '',
    };
  },
  created() {
    this.getpromotion();
  },
  methods: {
    getpromotion() {
      this.isPromotionLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/promos`, {
          params: {
            limit: 6,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.promotion = response.data.data;
          } else {
            this.promotion = [];
          }
          this.isPromotionLoading = false;
        })
        .catch((error) => {
          this.promotion = [];
          this.isPromotionLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'productList';

@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 6.7037vw 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-6 {
    width: 50%;
  }
}
</style>
